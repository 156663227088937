<template>
  <div>
    <RightSideBar
      v-if="isOpen"
      @submit="isOpen"
      @close="isOpen = false"
      :loading="isLoading"
      submit="Close"
      close-button
      button-class="bg-dynamicBackBtn text-white"
      >
      <template v-slot:title>
        <h1 class="text-darkPurple font-bold text-lg leading-5 p-0">
          View Pay Grade
        </h1>
      </template>
      <template v-if="Object.keys(paygrade).length">
        <div class="flex flex-col justify-center items-center gap-2">
          <Card class="flex justify-start items-start gap-2 px-3 py-2 w-full">
            <Icon icon-name="icon-display-grid" size="s" />
            <div class="flex flex-col justify-start items-start">
              <span class="font-semibold text-sm leading-5 text-mediumSeaGreen">
                PayGrade ID
              </span>
              <span class="font-normal text-base leading-5 text-jet">
                {{ paygrade.paygrade.payGradeId }}
              </span>
            </div>
          </Card>
          <Card class="flex justify-start items-start gap-2 px-3 py-2 w-full">
            <Icon icon-name="icon-display-grid" size="s" />
            <div class="flex flex-col justify-start items-start">
              <span class="font-semibold text-sm leading-5 text-mediumSeaGreen">
                Job Level
              </span>
              <span class="font-normal text-base leading-5 text-jet">
                {{ paygrade.level.name }}
              </span>
            </div>
          </Card>
          <Card class="flex justify-start items-start gap-2 px-3 py-2 w-full">
            <Icon icon-name="icon-display-grid" size="s" />
            <div class="flex flex-col justify-start items-start w-full">
              <span class="font-semibold text-sm leading-5 text-mediumSeaGreen">
                Location
              </span>
              <div v-if="(Array.isArray(paygrade.locations))" class="flex w-full">
                <div class="flex flex-row justify-between item-center w-full">
                  <div class="flex flex-row justify-between items-center gap-2">
                    <p class="text-sm text-darkPurple">{{ paygrade.locations[0].name }}</p>
                    <p v-if="((paygrade.locations.length - 1) > 0)" class="text-xs text-flame font-bold">
                      +{{ paygrade.locations.length - 1 }}
                    </p>
                  </div>
                  <template v-if="((paygrade.locations.length - 1) > 0)">
                    <div @click="isOpenLocations = true" class="flex items-center">
                      <p class="text-xs text-blueCrayola font-semibold cursor-pointer">View Trail</p>
                    </div>
                  </template>
                </div>
              </div>
              <span v-else class="font-normal text-sm leading-5 text-darkPurple">
                {{ paygrade.locations }}
              </span>
            </div>
          </Card>
          <Card class="flex justify-start items-start gap-2 px-3 py-2 w-full">
            <Icon icon-name="icon-display-grid" size="s" />
            <div class="flex flex-col justify-start items-start">
              <span class="font-semibold text-sm leading-5 text-mediumSeaGreen">
                Function
              </span>
              <div v-if="(Array.isArray(paygrade.functions))">
                <div v-for="item in paygrade.functions" :key="item.id" class="flex justify-start gap-2">
                  <span class="font-normal text-sm leading-5 text-darkPurple">
                    {{ item.name }}
                  </span>
                </div>
              </div>
              <span v-else class="font-normal text-sm leading-5 text-darkPurple">
                {{ paygrade.functions }}
              </span>
            </div>
          </Card>
          <Card class="flex justify-start items-start gap-2 px-3 py-2 w-full">
            <Icon icon-name="icon-display-grid" size="s" />
            <div class="flex flex-col justify-start items-start">
              <span class="font-semibold text-sm leading-5 text-mediumSeaGreen">
                Designations
              </span>
              <div v-if="(Array.isArray(paygrade.designations))">
                <div v-for="item in paygrade.designations" :key="item.id" class="flex justify-start gap-2">
                  <span class="font-normal text-sm leading-5 text-darkPurple">
                    {{ item.name }}
                  </span>
                </div>
              </div>
              <span v-else class="font-normal text-sm leading-5 text-darkPurple">
                {{ paygrade.designations }}
              </span>
            </div>
          </Card>
          <template v-if="paygrade.paygrade.currentMarketRate">
            <Card class="flex justify-start items-start gap-2 px-3 py-2 w-full">
              <Icon icon-name="icon-display-grid" size="s" />
              <div class="flex flex-col justify-start items-start">
                <span class="font-semibold text-sm leading-5 text-mediumSeaGreen">
                  Current Market Rate
                </span>
                <span class="font-normal text-base leading-5 text-jet">
                  {{ convertToCurrency(paygrade.paygrade.currentMarketRate) }}
                </span>
              </div>
            </Card>
          </template>
          <template v-if="paygrade.paygrade.policyPercent">
            <Card class="flex justify-start items-start gap-2 px-3 py-2 w-full">
              <Icon icon-name="icon-display-grid" size="s" />
              <div class="flex flex-col justify-start items-start">
                <span class="font-semibold text-sm leading-5 text-mediumSeaGreen">
                  Policy Percentage (%)
                </span>
                <span class="font-normal text-base leading-5 text-jet">
                  {{ paygrade.paygrade.policyPercent }}%
                </span>
              </div>
            </Card>
          </template>
          <template v-if="paygrade.paygrade.targetPercent">
            <Card class="flex justify-start items-start gap-2 px-3 py-2 w-full">
              <Icon icon-name="icon-display-grid" size="s" />
              <div class="flex flex-col justify-start items-start">
                <span class="font-semibold text-sm leading-5 text-mediumSeaGreen">
                  Target Percentile
                </span>
                <span class="font-normal text-base leading-5 text-jet">
                  {{ paygrade.paygrade.targetPercent }}%
                </span>
              </div>
            </Card>
          </template>
          <Card class="flex justify-start items-start gap-2 px-3 py-2 w-full">
            <Icon icon-name="icon-display-grid" size="s" />
            <div class="flex flex-col justify-start items-start">
              <span class="font-semibold text-sm leading-5 text-mediumSeaGreen">
                Annual Range Midpoint
              </span>
              <div class="font-normal text-base leading-5 text-jet">
                <span v-if="paygrade.paygrade.rangeMidPoint">
                  {{ convertToCurrency(paygrade.paygrade.rangeMidPoint) }}
                </span>
                <span v-else>XXXXXX</span>
              </div>
            </div>
          </Card>
          <Card class="flex justify-start items-start gap-2 px-3 py-2 w-full">
            <Icon icon-name="icon-display-grid" size="s" />
            <div class="flex flex-col justify-start items-start">
              <span class="font-semibold text-sm leading-5 text-mediumSeaGreen">
                Range Spread
              </span>
              <div class="font-normal text-base leading-5 text-jet">
                <span v-if="paygrade.paygrade.rangeSpread">
                  {{ paygrade.paygrade.rangeSpread.spread }}%
                </span>
                <span v-else>XXXXXX</span>
              </div>
            </div>
          </Card>
          <Card class="flex justify-start items-start gap-2 px-3 py-2 w-full">
            <Icon icon-name="icon-display-grid" size="s" />
            <div class="flex flex-col justify-start items-start">
              <span class="font-semibold text-sm leading-5 text-mediumSeaGreen">
                Annual Range Minimum
              </span>
              <div class="font-normal text-base leading-5 text-jet">
                <span v-if="paygrade.paygrade.rangeMinimum">
                  {{ convertToCurrency(paygrade.paygrade.rangeMinimum) }}
                </span>
                <span v-else>XXXXXX</span>
              </div>
            </div>
          </Card>
          <Card class="flex justify-start items-start gap-2 px-3 py-2 w-full">
            <Icon icon-name="icon-display-grid" size="s" />
            <div class="flex flex-col justify-start items-start">
              <span class="font-semibold text-sm leading-5 text-mediumSeaGreen">
                Annual Range Maximum
              </span>
              <div class="font-normal text-base leading-5 text-jet">
                <span v-if="paygrade.paygrade.rangeMaximum">
                  {{ convertToCurrency(paygrade.paygrade.rangeMaximum) }}
                </span>
                <span v-else>XXXXXX</span>
              </div>
            </div>
          </Card>
        </div>
      </template>
    </RightSideBar>
    <RightSideBar
      v-if="isOpenLocations"
      @close="isOpenLocations = false"
      button-class="bg-dynamicBackBtn text-white"
      :close-button="true"
    >
      <template v-slot:title>
        <div class="flex justify-start items-center">
          <p class="text-darkPurple font-bold text-lg leading-5 p-0 -ml-2">
            View All Locations
          </p>
        </div>
      </template>
      <template v-slot:default>
        <div class="flex flex-col justify-start gap-2">
          <Card v-for="location in paygrade.locations" :key="location.id" class="w-full">
            <div class="flex flex-row items-center justify-start gap-2 mx-5 my-3">
              <Icon icon-name="icon-grid" class="text-romanSilver" size="xxs" />
              <h6 class="font-semibold text-sm text-jet capitalize">{{ location.name }} </h6>
            </div>
          </Card>
        </div>
      </template>
    </RightSideBar>
  </div>
</template>

<script>
  import *as _ from "lodash"
  import Card from "@scelloo/cloudenly-ui/src/components/card";
  import RightSideBar from "@/components/RightSideBar";
  import Icon from "@/components/Icon";

  export default {
    components: {
      Card,
      RightSideBar,
      Icon
    },
    data(){
      return {
        isOpen: false,
        isLoading: true,
        isOpenLocations: false,
        paygrade: {}
      }
    },
    methods: {
      async onToggle(paygradeId){
        this.isLoading = true
        this.isOpen = true
        await this.findPayGradeById(paygradeId)
        this.isLoading = false
      },
      onClose(){
        this.isOpen = false
        this.isLoading = false
        _.assign(this.$data.payload, this.$options.data())

      },
      async findPayGradeById(paygradeId){
        await this.$_findPayGradeById(paygradeId).then(({ data }) => {
          this.paygrade = data.data
        })
      },
    },
  };
</script>

<style>
  .rightSidebar{
    width: 520px !important;
  }
</style>
